// App.js
import { useState, useEffect } from "react";
import "./App.css";
import curbingLogo from "./Assets/Curbing-logo_150sq.png";
// import Heart from "./Components/LikeButton";
import { FaInstagramSquare,FaFacebookSquare,FaEtsy } from "react-icons/fa";
import { BsPerson, BsEnvelopePlus } from "react-icons/bs";
import { AiFillShopping } from "react-icons/ai";

function App() {
  const pageTitle = "CURBING ART";

  const links = [
    {
      name: <AiFillShopping />,
      url: "https://curbingart.square.site/",
      title: "Shop",
    },
    {
      name: <FaInstagramSquare />,
      url: "https://instagram.com/curbing.art",
      title: "Instagram",
    },
    {
      name: <FaFacebookSquare />,
      url: "https://facebook.com/curbing.art",
      title: "FaceBook",
    },
    {
      name: <FaEtsy />,
      // name: <FaPaintBrush />,
      // url: "https://squareup.com/gift/MLH2Z75FJHDKN/order",  //gift card
      url: "https://curbingart.etsy.com",
      title: "Etsy"
    },
    //{ name: <FaPaintBrush />, url: "https://marshalwalden.com", title: "Portfolio" },
    // { name: <BsFillPenFill />, url: "https://marshalwalden.com/blog", title: "Blog" },
    {
      name: <BsPerson />,
      // name: <AiFillMail />,
      // url: "mailto:curbingart@gmail.com",
      url: "https://marshalwalden.com",
      title: "About",
    },
    {
      name: <BsEnvelopePlus />,
      url: "https://sendfox.com/curbingart",
      title: "Join Club",
    },
  ];

  const [currentPicIndex, setCurrentPicIndex] = useState(0);
  const [Links, setLinks] = useState(0);
  const profilePics = [curbingLogo, curbingLogo];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPicIndex((currentPicIndex + 1) % profilePics.length);
    }, 1000);
    return () => clearInterval(interval);
  }, [currentPicIndex, profilePics.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      setLinks((Links + 1) % profilePics.length);
    }, 1000);
    return () => clearInterval(interval);
  }, [Links, profilePics.length]);

  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);

  useEffect(() => {
    function handleOrientation(event) {
      const x = event.gamma; // horizontal tilt
      const y = event.beta; // vertical tilt

      // calculate the new position of the background based on device orientation
      const newTranslateX = -x / 10; // divide by 3 for slower movement
      const newTranslateY = -y / 10;

      // update the transform property with the new position
      setTranslateX(newTranslateX);
      setTranslateY(newTranslateY);
    }

    window.addEventListener("deviceorientation", handleOrientation);

    return () => {
      window.removeEventListener("deviceorientation", handleOrientation);
    };
  }, []);

  const backgroundStyle = {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 0,
    backgroundsize: "cover",
    backgroundclip: "content",
    transform: `translate3d(${translateX}px, ${translateY + 1}px, 0)`,
    transition: "transform 0.1s ease-in-out",
  };

  return (
    <main className=" text-white flex justify-center items-center min-h-screen top-0 left-0" style={backgroundStyle} >
      <div className={` flex flex-col justify-center items-center max-w-2xl mx-auto text-center bg-gradient-to-tr from-gray-500 via-purple-50 to-white animate-gradient p-4 m-12 rounded-lg shadow-lg shadow-black border border-purple-400 border-t-white border-r-white  z-10`}>
        <div className="flex justify-center items-center p-16 w-35 rounded-full ">
          <img src={curbingLogo} className="w-40 h-40 rounded-full absolute p-1 mt-4"></img>
        </div>
      
        {/* blinking logo */}
      {/* <a
        href="https://instagram.com/curbing.art"
        alt="Curbing Art"
        target="_blank"
        rel="noreferrer"
      >
        <div className="flex justify-center items-center p-16 w-28 rounded-full " >
          <img
            className={`w-32 h-32 rounded-full absolute p-3 ${
              currentPicIndex === 0
                ? "transition ease-in-out duration-700 delay-1000 opacity-100  bg-gradient-to-tl from-blue-900 via-cyan-900 to-sky-800 shadow-lg shadow-gray-500 border-blue-500 border" //transition-opacity duration-1000 bg-gradient-to-tl from-blue-700 to-blue-400 shadow-inner shadow-black
                : "transition ease-in-out duration-300 opacity-100 bg-sky-100 shadow-lg shadow-blue-100 border"
            }`}
            src={profilePics[currentPicIndex]}
            alt="Curbing Logo"
          />
        </div>
      </a> */}
        {/* title */}
       <br />
       {/*<h5>LET'S GO</h5> */}
      <h1 className="text-5xl drop-shadow-lg" id="title">
        {pageTitle}
        </h1>
        {/* <p className="text-white mt-4 bg-orange-500 w-full flex px-4 rounded-full py-1 text-sm">Curb Appeal of Provincetown</p> */}
        {/* annoucement */}
      {/*<a href="https://squareup.com/gift/MLH2Z75FJHDKN/order"
        alt="Click to purchase gift cards"
        target="_blank"
        rel="noreferrer"
      >
        <p className="text-white mt-4 bg-blue-500 w-full flex px-4 rounded-full py-1 text-sm">Click to Purchase Gift Cards</p>
      </a>*/}
      <ul className="button-title mt-6 text-center w-60 grid grid-cols-3 gap-3">
        {links.map((link, index) => (
          <li key={index}>
            <a
              title={link.title}
              id="curbing-buttons"
              className={`text-6xl flex-col w-auto p-1 items-center flex justify-center font-bold text-center shadow-md shadow-gray-500 text-gray-200  rounded-xl transition ease-in-out duration-500 hover:scale-105 border-blue-200 hover:shadow-lg hover:shadow-gray-600 hover:blur-0  hover:text-white hover:bg-gradient-to-tr hover:from-blue-700 hover:via-cyan-700 hover:to-sky-600 ${
                Links === 0
                  ? "transition ease-in-out duration-700 opacity-100 rotate-1 "
                  : "transition ease-in-out duration-300 opacity-100 -rotate-1"
              }`}
              href={link.url}
              target="_blank"
              rel="noreferrer"
            >
              {link.name}
              
            </a>
            {link.title}
          </li>
        ))}
      </ul>
        {/* <Heart/> */}
        <p></p>
        <hp className="text-sm mt-2  pt-2">
        Made With ❤ by marshal walden
        
        <div className="copyright">
                    &copy; Copyright <strong><span>{(new Date().getFullYear())}</span></strong> | curbing.art
                </div>
      </hp>
    </div>
    <div></div>
  </main>
  );
}

export default App;
